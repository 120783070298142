import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import config from "./config/config";

defineCustomElements(window);

function importBuildTarget() {
  if (config?.hololens) {
    return import('./hololens/AppWrapper');
  }
  else {
    return import('./components/App/AppWrapper');
  }
}

importBuildTarget().then(({ default: ImportedApp }) =>
  ReactDOM.render(
    <ImportedApp />,
    document.getElementById('root')
  )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
